(function($){

    function detectmob() { 
     if( navigator.userAgent.match(/Android/i)
     || navigator.userAgent.match(/webOS/i)
     || (navigator.userAgent.match(/Macintosh/i) && navigator.userAgent.match(/Safari/i))
     || navigator.userAgent.match(/iPhone/i)
     || navigator.userAgent.match(/iPad/i)
     || navigator.userAgent.match(/iPod/i)
     || navigator.userAgent.match(/BlackBerry/i)
     || navigator.userAgent.match(/Windows Phone/i)
     ){
        return true;
      }
     else {
        return false;
      }
    }


    var video = $('video').get(0);
    var videoVolum = 1;
    var $sound = $('.sound-control');

    video.volume = videoVolum;


    video.addEventListener('canplaythrough', function(){
        $('.loader').fadeOut(300);
        video.play();
    })

    $sound.click(function(){
      $sound.toggleClass('muted');
      video.muted= $sound.hasClass('muted');
    });

    if(!detectmob()){
        video.muted= false;
    } else {
        $sound.toggleClass('muted');
    }

    $(document).ready(function(){
        function helper_input(event){
            var el =  event.target ?  event.target : this;
            if($(el).val() === ''){
                var $label = $(el).removeClass('input-filled error');
            } else {
                $(el).addClass('input-filled');
            }
        }

        $('.form-text input').each(helper_input).focusout(helper_input);

        var $links = $('header .menu a');
        $links.click(function(){

            $('body').removeClass('mobile-menu-visible');
            hideMenu();

            var $form = $($(this).attr('href'));
            var $activeForm = $('.forms form:visible');

            if($(this).hasClass('active')) return false;
            
            $links.removeClass('active');
            $(this).addClass('active');
            $('.video-wrapper').addClass('overlay');
            
            if($activeForm.length){
                $activeForm.animate({opacity: 0, marginTop: -200}, 300, function(){
                    $(this).hide();
                    $form.css({opacity: 0, marginTop: 200}).show().animate({opacity: 1, marginTop: 0}, 300);
                });
            } else {
                $form.css({opacity: 0, marginTop: 200}).show().animate({opacity: 1, marginTop: 0}, 300);
            }
            return false;
        });

        $('.menu-toggle').click(function(){
            $('body').toggleClass('mobile-menu-visible');
            if($('body').hasClass('mobile-menu-visible')){
                showMenu();
            } else {
                hideMenu();
            }
            return false;
        });

        function removeThisEvent(){
            return false;
        }

        $(window).resize(function(){
            $('body').removeClass('mobile-menu-visible');
            hideMenu();
        });

        function showMenu(){
            $('.menu').stop().css({opacity: 0}).show().animate({opacity: 1});
        }
        function hideMenu(){
            $('.menu').stop().animate({opacity: 0}, function(){$(this).hide();});
        }

        $(document).click(function(e){
            if(!$(e.target).closest('form').length){
                $('.forms form:visible').animate({opacity: 0, marginTop: -200}, 300, function(){
                    $(this).hide();
                    $('.video-wrapper').removeClass('overlay');
                    $links.removeClass('active');
                });
            }
        })
    });
})(jQuery)